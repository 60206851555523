import InfiniteTable from "../InfiniteTable/InfiniteTable";
import { getGroups } from "../../services/GroupService";
import { urls } from "../../utils/constants";
import { extractIdFromJsonLd } from "../../utils/api_platform";
import { Column } from "react-virtualized";
import { Link } from "react-router-dom";

const Groups = () => (
  <>
    <h1>GROUPES</h1>
    <Link
      to={urls.users.group.new}
      className="uk-button uk-button-secondary uk-button-small"
    >
      <span uk-icon="plus"></span>
    </Link>
    <InfiniteTable
      getItems={getGroups}
      editItemUrl={rowData =>
        `${urls.users.group.edit}/${extractIdFromJsonLd(rowData["@id"])}`
      }
    >
      <Column label="Nom" dataKey="name" width={400} disableSort={true} />
    </InfiniteTable>
  </>
);

export default Groups;
