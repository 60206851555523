import { useEffect } from "react";
import useForm from "react-hook-form";
import ApplicationFormErrors from "./ApplicationFormErrors";

const ApplicationForm = ({ onSubmit, values = { name: "", url: "", code: "" } }) => {
  const { register, errors, setValue, handleSubmit } = useForm();
  const { name, url, code } = values;

  useEffect(() => {
    setValue("name", name);
    setValue("url", url);
    setValue("code", code);
  }, [name, url, code, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ApplicationFormErrors errors={errors} />
      <p>
        <input
          className="uk-input"
          type="text"
          placeholder="Nom..."
          name="name"
          ref={register({ required: true })}
        />
      </p>
      <p>
        <input
          className="uk-input"
          type="text"
          placeholder="URL..."
          name="url"
          ref={register}
        />
      </p>
      <p>
        <input
          className="uk-input"
          type="text"
          placeholder="Code..."
          name="code"
          ref={register}
        />
      </p>
      <button className="uk-button uk-button-secondary">
        <span uk-icon="check"></span> ENREGISTRER
      </button>
    </form>
  );
};

export default ApplicationForm;
