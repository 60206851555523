import { useState, useRef } from "react";
import useForm from "react-hook-form";
import { DoubleBounce } from "better-react-spinkit";
import Reaptcha from "reaptcha";
import history from "../../utils/history";

import logo from "../../images/logo.png";
import "./Login.scss";
import LoginErrors from "./LoginErrors";
import login from "../../utils/login-utils";
import { useStateValue } from "../../state/state";
import { urls, TOKEN_NAME } from "../../utils/constants";
import TextInput from "../../utils/TextInput";

const Login = () => {
  const {
    register,
    errors,
    setError,
    clearError,
    handleSubmit,
    setValue
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useStateValue();

  let captchaRef = useRef(null);

  const verifyCallback = async () => {
    const response = await captchaRef.current.getResponse();
    setValue("captchaResponse", response, false);
    clearError("captchaResponse");
  };

  const onSubmit = data => {
    setLoading(true);

    login(data.login, data.password, data.captchaResponse)
      .then(response => {
        if (response.status === 403) {
          setError("apiServer", "connection", "Accès non autorisé");
        } else {
          setError(
            "apiServer",
            "connection",
            "Les informations fournies n'ont pas permis de vous identifier"
          );
        }
        setLoading(false);
        if (response.status < 200 || response.status >= 300) {
          const data = response.data;
          let errMsg = "Une erreur est survenue";
          if (typeof data["hydra:description"] !== undefined) {
            errMsg = data["hydra:description"];
          }

          setError("apiServer", "connection", errMsg);
          throw new Error(response);
        }

        return response.json();
      })
      .then(({ token, username }) => {
        localStorage.setItem(TOKEN_NAME, token);
        dispatch({ type: "changeUsername", username: username });
        history.push(urls.users.list);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div className="uk-container uk-container-expand login">
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="uk-text-center">
          <img src={logo} alt="F2A Logo" />
        </p>
        <LoginErrors errors={errors} />
        <p>
          <label htmlFor="login">Login</label>
          <TextInput
            name="login"
            id="login"
            ref={register({ required: true })}
          />
        </p>
        <p>
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            name="password"
            className="uk-input"
            id="password"
            ref={register({ required: true })}
          />
        </p>
        <div>
          <input
            type="hidden"
            name="captchaResponse"
            ref={register({ required: true })}
          />
          <Reaptcha
            ref={captchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
            onVerify={verifyCallback}
          />
        </div>
        <div className="uk-text-center uk-margin-top">
          {loading ? (
            <div className="uk-flex uk-flex-center">
              <DoubleBounce />
            </div>
          ) : (
            <button className="uk-button uk-button-primary" disabled={loading}>
              Connexion
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Login;
