import UIkit from "uikit";

export const confirm = (message, confirmCallBack, cancelCallBack) => {
  UIkit.modal.confirm(message).then(
    function() {
      confirmCallBack();
    },
    function() {
      cancelCallBack();
    }
  );
};

export const prompt = (title, description, confirmCallBack) => {
  UIkit.modal.prompt(title, description).then(function(response) {
    confirmCallBack(response);
  });
};
