import { SortDirection } from 'react-virtualized';

export const tableSortReducer = (state, action) => {
  switch(action.type) {
    case "changeDirection":
      return {
        ...state,
        direction: action.payload
      }
    case "changeColumn":
      return {
        column: action.payload,
        direction: SortDirection.ASC
      };
    case "reset":
      return action.payload;
    default:
      return state;
  }
}