import { useTranslation } from "react-i18next";

const ResetErrors = ({ errors }) => {
  const { t } = useTranslation();

  return (
  (errors && Object.keys(errors).length > 0 && (
    <div className="uk-alert-danger" uk-alert="true">
      {errors.password && (
        <>
          {t('reset.password-error')}
          <br />
        </>
      )}
    </div>
  )) || <div></div>
)};

export default ResetErrors;