const LanguageSwitcher = ({ changeLanguageFn }) => (
  <>
    <button
      className="uk-button uk-button-secondary uk-button-small uk-margin-right"
      onClick={e => {
        e.preventDefault();
        changeLanguageFn("fr");
      }}
    >
      FR
    </button>
    <button
      className="uk-button uk-button-secondary uk-button-small"
      onClick={e => {
        e.preventDefault();
        changeLanguageFn("en");
      }}
    >
      EN
    </button>
  </>
);

export default LanguageSwitcher;
