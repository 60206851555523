import { useState } from "react";
import getLoggedInstance, { getInstance } from "./request-utils";
import RotatingPlane from "better-react-spinkit/dist/RotatingPlane";

const FileDownload = ({ anonymous = false, url, filename, children }) => {
  const [loading, setLoading] = useState(false);

  const download = () => {
    setLoading(true);
    const client = anonymous ? getInstance() : getLoggedInstance();

    client
      .get(url, {
        responseType: "blob"
      })
      .then(res => {
        const dataUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.style = "display: none";
        link.href = dataUrl;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .then(() => {
        setLoading(false);
      });
  };

  return loading ? (
    <><RotatingPlane /> Chargement...</>
  ) : (
    <button
      className="uk-button uk-button-primary uk-button-small"
      onClick={download}
    >
      {children}
    </button>
  );
};

export default FileDownload;
