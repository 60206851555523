import { withRouter } from 'react-router-dom';
import notify from "../../utils/notification";
import { urls } from "../../utils/constants";
import { newApplication } from '../../services/ApplicationService';
import ApplicationForm from './ApplicationForm';

const ApplicationNew = ({ history }) => {
  const onSubmit = data => {
    newApplication(data)
      .then(res => {
        notify('Enregistrement créé');
        history.push(urls.applications.list);
      })
      .catch(err => console.error(err));
  }

  return (
    <>
      <h1>Nouvelle application</h1>
      <ApplicationForm onSubmit={onSubmit} />
    </>
  );
  };

export default withRouter(ApplicationNew);