import { withRouter } from 'react-router-dom';
import { newOrigin } from "../../services/OriginService";
import notify from "../../utils/notification";
import { urls } from "../../utils/constants";
import OriginForm from "./OriginForm";

const NewOrigin = ({ history }) => {
  const onSubmit = data => {
    newOrigin(data)
      .then(res => {
        notify('Enregistrement créé');
        history.push(urls.users.origin.list);
      })
      .catch(err => console.error(err));
  }

  return (
    <>
      <h1>Nouvelle provenance</h1>
      <OriginForm onSubmit={onSubmit} />
    </>
  );
};

export default withRouter(NewOrigin);
