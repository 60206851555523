const UserFormErrors = ({ errors }) =>
  errors &&
  Object.keys(errors).length > 0 && (
    <div className="uk-alert-danger" uk-alert="true">
      {errors.login && <div>Le login est obligatoire</div>}
      {errors.email && <div>L'email est obligatoire</div>}
      {errors.firstName && <div>Le prénom est obligatoire</div>}
      {errors.lastName && <div>Le nom est obligatoire</div>}
    </div>
  );

export default UserFormErrors;
