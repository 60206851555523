import ThreeBounce from "better-react-spinkit/dist/ThreeBounce";
import { useStateValue } from "../../state/state";
import { TOKEN_NAME, urls } from "../../utils/constants";

const Logout = ({ history }) => {
  const [, dispatch] = useStateValue();
  localStorage.removeItem(TOKEN_NAME);
  dispatch("logout");

  history.push(urls.users.login);

  return <ThreeBounce />;
};

export default Logout;
