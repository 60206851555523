import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { editGroup, getGroup } from "../../services/GroupService";
import notify from "../../utils/notification";
import { urls } from "../../utils/constants";
import GroupForm from "./GroupForm";
import DoubleBounce from "better-react-spinkit/dist/DoubleBounce";

const EditGroup = ({ match, history }) => {
  const [group, setGroup] = useState(null);
  const [error, setError] = useState(false);

  const { id } = match.params;

  const onSubmit = (data) => {
    setError(false);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("esonieCustomPrint", data.esonieCustomPrint ? "1" : "0");
    if (data.headerLogo.length === 1) {
      formData.append(
        "headerLogo",
        data.headerLogo ? data.headerLogo[0] : null,
        data.headerLogo ? data.headerLogo[0].name : null
      );
    }
    if (data.footerLogo.length === 1) {
      formData.append(
        "footerLogo",
        data.footerLogo ? data.footerLogo[0] : null,
        data.footerLogo ? data.footerLogo[0].name : null
      );
    }
    editGroup(id, formData)
      .then((res) => {
        notify("Enregistrement mis à jour");
        history.push(urls.users.group.list);
      })
      .catch((err) => setError(true));
  };

  useEffect(() => {
    getGroup(id).then((res) => setGroup(res.data));
  }, [id]);

  return (
    <>
      <h1>Editer groupe</h1>
      {error && (
        <div style={{ color: "#F00" }}>
          Une erreur est survenue. Si vous avez essayé d'enregistrer une image
          pour ce groupe, vérifiez bien qu'elle respecte les propriétés
          suivantes :
          <ul>
            <li>Extension : .png ou .svg</li>
            <li>Taille maximum : 50ko</li>
          </ul>
        </div>
      )}
      <div className="uk-width-small uk-margin-bottom">
        {group && group.headerLogoUrl && (
          <>
            <p>Logo haut de page :</p>
            <img
              src={process.env.REACT_APP_API_ENTRYPOINT + group.headerLogoUrl}
              alt={group.name}
            />
          </>
        )}
      </div>
      {group && !group.headerLogoUrl && (
        <p>
          Aucun logo de haut de page enregistré. Si vous activez la
          personnalisation de l'impression PDF dans Esonie, enregistrez un logo
          pour ce groupe.
        </p>
      )}
      <div className="uk-width-small uk-margin-bottom">
        {group && group.footerLogoUrl && (
          <>
            <p>Logo bas de page :</p>
            <img
              src={process.env.REACT_APP_API_ENTRYPOINT + group.footerLogoUrl}
              alt={group.name}
            />
          </>
        )}
      </div>
      {group && !group.footerLogoUrl && (
        <p>
          Aucun logo de bas de page enregistré. Si vous activez la
          personnalisation de l'impression PDF dans Esonie, enregistrez un logo
          pour ce groupe.
        </p>
      )}
      {!group ? (
        <DoubleBounce />
      ) : (
        <GroupForm onSubmit={onSubmit} values={group} />
      )}
    </>
  );
};

export default withRouter(EditGroup);
