import { useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo.png";
import LanguageSwitcher from "../Language/LanguageSwitcher";
import RegisterForm from "./RegisterForm";
import notify from "../../utils/notification";

import "./Register.scss";
import { registerUser } from "../../services/UserService";

const Register = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const onSubmit = (data) => {
    setLoading(true);

    registerUser(data)
      .then((res) => notify(t("register.success")))
      .catch((err) => {
        const data = err.response.data;
        let errMsg = t("global.error_occured");
        if (typeof data["translatable-msg-key"] !== undefined) {
          errMsg = t(data["translatable-msg-key"]).replace(
            "[esonie_url]",
            process.env.REACT_APP_ESONIE_URL
          );
        } else if (typeof data["hydra:description"] !== undefined) {
          errMsg = data["hydra:description"];
        }
        notify(errMsg, "danger");
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <div className="uk-container uk-container-xsmall uk-background-muted register-container">
      <p className="uk-margin-top	uk-margin-bottom">
        <img src={logo} alt="F2A" className="uk-align-center" />
      </p>
      <p className="uk-text-center">
        <LanguageSwitcher changeLanguageFn={changeLanguage} />
      </p>
      <h1>{t("register.subscription_request")}</h1>
      <RegisterForm loading={loading} onSubmit={onSubmit} />
    </div>
  );
};

export default Register;
