import { useEffect } from "react";
import useForm from "react-hook-form";
import OriginFormErrors from "./OriginFormErrors";

const OriginForm = ({ onSubmit, values = { name: "" } }) => {
  const { register, errors, setValue, handleSubmit } = useForm();
  const { name } = values;

  useEffect(() => {
    setValue('name', name);
  }, [name, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <OriginFormErrors errors={errors} />
      <p>
        <input
          className="uk-input"
          type="text"
          placeholder="Nom..."
          name="name"
          ref={register({ required: true })}
        />
      </p>
      <button className="uk-button uk-button-secondary">
        <span uk-icon="check"></span> ENREGISTRER
      </button>
    </form>
  );
};

export default OriginForm;
