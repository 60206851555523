import { useState } from "react";
import notify from "../../utils/notification";
import { grantAdminRole } from "../../services/UserService";

const GrantAdminRole = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const requestAdminRole = (userId) => {
    setLoading(true);
    grantAdminRole(userId)
      .then(() => notify("Droits administrateur accordés"))
      .catch((e) => notify("Une erreur est survenue"))
      .finally(() => setLoading(false));
  };

  return (
    <p>
      <button
        className="uk-button uk-button-primary"
        type="button"
        onClick={() => requestAdminRole(id)}
        disabled={loading}
      >
        Accorder les droits administrateur
      </button>
    </p>
  );
};

export default GrantAdminRole;
