import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useForm from "react-hook-form";
import Circle from "better-react-spinkit/dist/Circle";
import Reaptcha from "reaptcha";
import Select from "react-select";

import useIsMounted from "../../hooks/useIsMounted";
import RemoteDataSelectList from "../../utils/RemoteDataSelectList";
import { getCountries, getListItems } from "../../services/CountryService";
import { getOriginsAnonymous } from "../../services/OriginService";
import RegisterFormErrors from "./RegisterFormErrors";
import { getApplicationsAnonymous } from "../../services/ApplicationService";

const RegisterForm = ({ loading, onSubmit }) => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedOrigin, setSelectedOrigin] = useState("");
  const [apps, setApps] = useState([]);
  const [selectedApps, setSelectedApps] = useState([]);
  const [loadingApps, setLoadingApps] = useState(true);
  const isMounted = useIsMounted();

  const { register, watch, errors, clearError, handleSubmit, setValue } =
    useForm();

  const changeCountry = (country) => {
    setValue("country", country.value);
    clearError("country");
    setSelectedCountry(country);
  };

  const changeOrigin = (origin) => {
    setValue("origin", origin.value);
    clearError("origin");
    setSelectedOrigin(origin);
  };

  const changeAppsSelected = (data) => {
    const selectedApps = data.map((app) => app["value"]);
    setValue("apps", selectedApps);
    setSelectedApps(data);
  };

  let captchaRef = useRef(null);

  const verifyCallback = async () => {
    const response = await captchaRef.current.getResponse();
    setValue("captchaResponse", response, false);
    clearError("captchaResponse");
  };

  useEffect(() => {
    getApplicationsAnonymous().then((res) => {
      const options = getListItems(res);
      if (isMounted) {
        setApps(options);
        setLoadingApps(false);
      }
    });
  }, [isMounted]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RegisterFormErrors errors={errors} />
      <div className="uk-margin">
        <input
          className="uk-input uk-form-width-large"
          type="text"
          name="login"
          placeholder={t("register.login")}
          ref={register({ required: t("register.error.login") })}
        />
      </div>
      <div className="uk-margin">
        <input
          className="uk-input uk-form-width-large"
          type="text"
          name="lastName"
          placeholder={t("register.lastName")}
          ref={register({ required: t("register.error.lastName") })}
        />
      </div>
      <div className="uk-margin">
        <input
          className="uk-input uk-form-width-large"
          type="text"
          name="firstName"
          placeholder={t("register.firstname")}
          ref={register({ required: t("register.error.firstname") })}
        />
      </div>
      <div className="uk-margin">
        <input
          className="uk-input uk-form-width-large"
          type="email"
          name="email"
          placeholder={t("register.email")}
          ref={register({ required: t("register.error.email") })}
        />
      </div>
      <div className="uk-margin">
        <input
          className="uk-input uk-form-width-large"
          type="text"
          name="company"
          placeholder={t("register.company")}
          ref={register({ required: t("register.error.company") })}
        />
      </div>
      <div className="uk-margin">
        <input
          className="uk-input uk-form-width-large"
          type="text"
          name="city"
          placeholder={t("register.city")}
          ref={register}
        />
      </div>
      <div className="uk-margin">
        <input
          className="uk-input uk-form-width-large"
          type="text"
          name="postalCode"
          placeholder={t("register.postalCode")}
          ref={register}
        />
      </div>
      <div className="uk-margin">
        <input
          className="uk-input uk-form-width-large"
          type="password"
          name="password"
          placeholder={t("register.password")}
          ref={register({ required: t("register.error.password") })}
        />
      </div>
      <div className="uk-margin">
        <input
          className="uk-input uk-form-width-large"
          type="password"
          name="confirmPassword"
          placeholder={t("register.confirmPassword")}
          ref={register({
            required: t("register.error.confirmPassword"),
            validate: (value) =>
              value !== watch("password")
                ? t("register.error.mismatchPassword")
                : undefined,
          })}
        />
      </div>
      <div className="uk-margin">
        <span>{t("register.country")} :</span>
        <input
          type="hidden"
          name="country"
          ref={register({ required: t("register.error.country") })}
        />
        <RemoteDataSelectList
          selectedItem={selectedCountry}
          changeItemFn={changeCountry}
          loadingText={`${t("register.loading_countries")}...`}
          getItems={getCountries}
          anonymous={true}
          getListItems={getListItems}
        />
      </div>
      <div className="uk-margin">
        <span>{t("register.origin")} :</span>
        <input
          type="hidden"
          name="origin"
          ref={register({ required: t("register.error.origin") })}
        />
        <RemoteDataSelectList
          selectedItem={selectedOrigin}
          changeItemFn={changeOrigin}
          loadingText={`${t("register.loading_origins")}...`}
          getItems={getOriginsAnonymous}
          anonymous={true}
          getListItems={getListItems}
        />
      </div>
      <div className="uk-margin">
        <span>{t("register.apps")} :</span>
        <input
          type="hidden"
          name="apps"
          ref={register({ required: t("register.error.apps") })}
        />
        {loadingApps ? (
          <div>
            <span>{t("register.loading_apps")}...</span>
            <Circle />
          </div>
        ) : (
          <Select
            isMulti
            options={apps}
            onChange={changeAppsSelected}
            value={selectedApps}
          />
        )}
      </div>
      <div>
        <input
          type="hidden"
          name="captchaResponse"
          ref={register({ required: t("register.error.captchaResponse") })}
        />
        <Reaptcha
          ref={captchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
          onVerify={verifyCallback}
        />
      </div>
      <div className="uk-margin">
        <button
          className="uk-button uk-button-primary uk-align-center"
          disabled={loading}
        >
          {loading ? (
            <Circle />
          ) : (
            <>
              {t("register.send")} <span uk-icon="arrow-right"></span>
            </>
          )}
        </button>
      </div>
    </form>
  );
};

export default RegisterForm;
