import notify from "../../utils/notification";

const ReinitLink = ({ user }) => {
  const copyReinitLink = () => {
    navigator.clipboard.writeText(
      process.env.REACT_APP_URL + "/reset/" + user.tokenReinit
    );
    notify("Lien copié dans le presse-papier");
  };

  return (
    user["tokenReinit"] && (
      <div
        className="uk-alert-primary uk-padding-small uk-margin-bottom"
        uk-alert="true"
      >
        <p>Cet utilisateur a réinitialisé son mot de passe</p>

        {user["tokenValid"]["valid"] === true ? (
          <p className="uk-text-bold">
            Durée de validation du lien : {user["tokenValid"]["diff"]}
          </p>
        ) : (
          <p className="uk-text-bold">
            Le lien de réinitialisation a expiré, demandez à l'utilisateur de
            procéder à une nouvelle réinitialisation
          </p>
        )}

        {user["tokenValid"]["valid"] === true && (
          <p>
            <button
              className="uk-button uk-button-secondary"
              type="button"
              onClick={() => copyReinitLink()}
            >
              Copier le lien de réinitialisation
            </button>
          </p>
        )}
      </div>
    )
  );
};

export default ReinitLink;
