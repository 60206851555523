export default class User {
  constructor(
    id = 0,
    email = "",
    login = "",
    firstName = "",
    lastName = "",
    gender = "",
    locked = false,
    codeErp = "",
    passwordFailedNumber = 0,
    company = "",
    city = "",
    country = "",
    hasValidatedCgu = false,
    companyGroup = "",
    commercial = "",
    origin = "",
    registrationDate = null,
    phoneNumbers = [],
    applications = [],
    postalCode = "",
    f2AirClient = false,
    tokenReinit = null,
    dateTokenReinit = null,
    tokenValid = null
  ) {
    this.id = id;
    this.email = email;
    this.login = login;
    this.firstName = firstName;
    this.lastName = lastName;
    this.gender = gender;
    this.locked = locked;
    this.codeErp = codeErp;
    this.passwordFailedNumber = passwordFailedNumber;
    this.company = company;
    this.city = city;
    this.country = country;
    this.hasValidatedCgu = hasValidatedCgu;
    this.companyGroup = companyGroup;
    this.commercial = commercial;
    this.origin = origin;
    this.registrationDate = registrationDate;
    this.phoneNumbers = phoneNumbers;
    this.applications = applications;
    this.postalCode = postalCode;
    this.f2AirClient = f2AirClient;
    this.tokenReinit = tokenReinit;
    this.dateTokenReinit = dateTokenReinit;
    this.tokenValid = tokenValid;
  }

  static getPreparedData(data, phoneNumbers = [], apps = []) {
    const preparedPhoneNumbers = phoneNumbers.map((phoneNumber) => {
      if ("@id" in phoneNumber) {
        return {
          "@id": phoneNumber["@id"],
          phone: phoneNumber.phone,
        };
      }

      return {
        phone: phoneNumber.phone,
      };
    });

    const preparedApps = apps.map((app) => {
      if (app["@id"] !== undefined) {
        return app["@id"];
      }
      if (app["value"] !== undefined) {
        return app["value"];
      }

      return app;
    });

    const formattedData = {
      ...data,
      country: data.country && data.country !== "" ? data.country : null,
      companyGroup:
        data.companyGroup && data.companyGroup !== ""
          ? data.companyGroup
          : null,
      commercial:
        data.commercial && data.commercial !== "" ? data.commercial : null,
      origin: data.origin && data.origin !== "" ? data.origin : null,
      phoneNumbers: preparedPhoneNumbers,
      applications: preparedApps,
    };

    return formattedData;
  }

  static fromUserData(userData) {
    return new User(
      userData.id,
      userData.email,
      userData.login,
      userData.firstName,
      userData.lastName,
      userData.gender,
      userData.locked,
      userData.codeErp,
      userData.passwordFailedNumber,
      userData.company,
      userData.city,
      userData.country
        ? {
            value: userData.country["@id"],
            label: userData.country["name"],
          }
        : "",
      userData.hasValidatedCgu,
      userData.companyGroup ? userData.companyGroup : "",
      userData.commercial ? userData.commercial : "",
      userData.origin ? userData.origin : "",
      userData.registrationDate ? userData.registrationDate : null,
      userData.phoneNumbers,
      userData.applications,
      userData.postalCode,
      userData.f2AirClient,
      userData.tokenReinit,
      userData.tokenReinitDate,
      userData.tokenValid
    );
  }
}
