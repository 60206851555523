import { useEffect } from "react";
import useForm from "react-hook-form";
import GroupFormErrors from "./GroupFormErrors";

const GroupForm = ({
  onSubmit,
  values = { name: "", esonieCustomPrint: false },
}) => {
  const { register, errors, setValue, handleSubmit } = useForm();
  const { name, esonieCustomPrint } = values;

  useEffect(() => {
    setValue("name", name);
  }, [name, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <GroupFormErrors errors={errors} />
      <p>
        <input
          className="uk-input"
          type="text"
          placeholder="Nom..."
          name="name"
          ref={register({ required: true })}
        />
      </p>
      <p>
        <label htmlFor="headerLogo">Logo haut de page : </label>
        <input
          type="file"
          id="headerLogo"
          name="headerLogo"
          ref={register("headerLogo")}
        />
      </p>
      <p>
        <label htmlFor="footerLogo">Logo bas de page : </label>
        <input
          type="file"
          id="footerLogo"
          name="footerLogo"
          ref={register("footerLogo")}
        />
      </p>
      <p>
        <input
          type="checkbox"
          name="esonieCustomPrint"
          id="esonieCustomPrint"
          defaultChecked={esonieCustomPrint}
          ref={register("esonieCustomPrint")}
        />
        <label htmlFor="esonieCustomPrint">
          Activer la personnalisation de l'impression Esonie
        </label>
      </p>
      <button className="uk-button uk-button-secondary">
        <span uk-icon="check"></span> ENREGISTRER
      </button>
    </form>
  );
};

export default GroupForm;
