import { useState } from "react";
import { withRouter } from "react-router-dom";

import UserForm from "./UserForm";
import { createUser } from "../../services/UserService";
import notify from "../../utils/notification";
import { urls } from "../../utils/constants";
import { randomString } from "../../utils/utils";

const NewUser = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [apps, setApps] = useState([]);

  const onPhoneCreated = num => {
    if (num === null) {
      return;
    }
    
    const newPhoneNumber = {
      listKey: randomString(),
      phone: num
    };
    setPhoneNumbers([...phoneNumbers, newPhoneNumber]);
  };

  const removePhone = phoneId => {
    const filteredPhones = phoneNumbers.filter(phoneNumber => {
      return phoneNumber.listKey !== phoneId;
    })

    setPhoneNumbers(filteredPhones);
  }

  const onAppChange = e => {
    const checkbox = e.target;

    if (checkbox.checked) {
      // adding app
      const newApp = checkbox.dataset.idapp;
      const modifiedApps = [...apps, newApp];
      setApps(modifiedApps);
    } else {
      const removeApp = checkbox.dataset.idapp;
      const modifiedApps = apps.filter(app => app !== removeApp);
      setApps(modifiedApps);
    }
  };

  const onSubmit = data => {
    setLoading(true);

    createUser(data, phoneNumbers, apps).then(
      res => {
        notify("Enregistrement créé");
        setLoading(false);
        history.push(urls.users.list);
      },
      err => {
        notify(
          "Une erreur est survenue. Essayez de vous déconnecter puis de vous reconnecter",
          'danger'
        );
        setLoading(false);
        history.push(urls.users.list);
        console.log(err);
      }
    );
  };

  return (
    <>
      <h1>Nouvel utilisateur</h1>
      <UserForm
        onSubmit={onSubmit}
        loading={loading}
        phoneNumbers={phoneNumbers}
        onPhoneCreated={onPhoneCreated}
        removePhone={removePhone}
        apps={apps}
        onAppChange={onAppChange}
      />
    </>
  );
};

export default withRouter(NewUser);
