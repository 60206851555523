import { useState } from "react";
import { getUsers } from "../../services/UserService";
import { Column } from "react-virtualized";
import { urls, userApiEndpoint } from "../../utils/constants";
import { extractIdFromJsonLd } from "../../utils/api_platform";
import InfiniteTable from "../InfiniteTable/InfiniteTable";
import { SortIndicator } from "react-virtualized";
import { Link } from "react-router-dom";
import UserSearch from "./Search";
import initialUserSort from "../../state/userSort";
import FileDownload from "../../utils/FileDownload";

const tableOptions = {
  hideRemoveButton: true,
};

const Users = () => {
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length >= 3) {
      setSearch(e.target.value);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setSearchValue("");
    setSearch("");
  };

  const headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => {
    return (
      <div>
        {label}
        {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
      </div>
    );
  };

  return (
    <>
      <h1>UTILISATEURS</h1>
      <div className="uk-flex uk-flex-row">
        <Link
          to={urls.users.new}
          className="uk-button uk-button-secondary uk-button-small"
        >
          <span uk-icon="plus"></span>
        </Link>
        <div className="uk-margin-left">
          <FileDownload
            url={`${userApiEndpoint}.csv?pagination=false`}
            filename="f2a_users.csv"
          >
            EXPORT CSV <span uk-icon="download"></span>
          </FileDownload>
        </div>
      </div>
      <UserSearch
        searchTerm={searchValue}
        handleChange={handleSearchChange}
        handleReset={handleReset}
      />
      <InfiniteTable
        getItems={getUsers}
        search={search}
        editItemUrl={(rowData) =>
          `${urls.users.edit}/${extractIdFromJsonLd(rowData["@id"])}`
        }
        options={tableOptions}
        initialSort={initialUserSort}
      >
        <Column label="Login" dataKey="login" width={130} disableSort={true} />
        <Column label="Nom" dataKey="lastName" width={130} disableSort={true} />
        <Column
          width={130}
          label="Prénom"
          dataKey="firstName"
          disableSort={true}
        />
        <Column
          width={150}
          label="Société"
          dataKey="company"
          cellRenderer={({ rowData }) => rowData.company}
          headerRenderer={headerRenderer}
        />
        <Column
          width={100}
          label="Groupe"
          dataKey="companyGroup.name"
          cellRenderer={({ rowData }) =>
            rowData.companyGroup ? rowData.companyGroup.name : "-"
          }
          headerRenderer={headerRenderer}
        />
        <Column
          width={70}
          label="Actif"
          dataKey="locked"
          cellRenderer={({ rowData }) =>
            rowData.locked ? (
              <span className="uk-badge uk-label-danger" title="Compte bloqué">
                <span data-uk-icon="close"></span>
              </span>
            ) : (
              <span className="uk-badge bg-success" title="Compte actif">
                <span data-uk-icon="check"></span>
              </span>
            )
          }
          headerRenderer={headerRenderer}
        />
        <Column
          width={120}
          label="Pays"
          dataKey="country.name"
          cellRenderer={({ rowData }) =>
            rowData.country ? rowData.country.name : "-"
          }
          headerRenderer={headerRenderer}
        />
        <Column
          width={135}
          label="Commercial"
          dataKey="commercial.name"
          cellRenderer={({ rowData }) =>
            rowData.commercial ? rowData.commercial.name : "-"
          }
          headerRenderer={headerRenderer}
        />
        <Column
          width={135}
          label="Provenance"
          dataKey="origin.name"
          cellRenderer={({ rowData }) =>
            rowData.origin ? rowData.origin.name : "-"
          }
          headerRenderer={headerRenderer}
        />
        <Column
          width={62}
          label="Accès"
          dataKey="applications"
          cellRenderer={({ rowData }) => {
            let apps = rowData.applications.reduce((apps, currentApp) => {
              let prefix = "";
              if (apps !== "") {
                prefix = apps + ", ";
              }
              return prefix + currentApp.name;
            }, "");

            if (apps !== "") {
              return (
                <span
                  className="uk-badge uk-text-large"
                  uk-tooltip={`title: ${apps}`}
                >
                  <span uk-icon="check"></span>
                </span>
              );
            } else {
              return (
                <span
                  className="uk-badge uk-text-large uk-label-danger"
                  uk-tooltip={`title: Aucun accès autorisé`}
                >
                  <span uk-icon="close"></span>
                </span>
              );
            }
          }}
          headerRenderer={headerRenderer}
          disableSort={true}
        />
        <Column
          label="Inscription"
          dataKey="registrationDate"
          width={115}
          disableSort={true}
        />
      </InfiniteTable>
    </>
  );
};

export default Users;
