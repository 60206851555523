import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useQuery from "../../hooks/useQuery";

import logo from "../../images/logo.png";
import "./TokenHandler.scss";
import { Circle } from "better-react-spinkit";
import { getInstance } from "../../utils/request-utils";
import { checkJwtTokenEndpoint, TOKEN_NAME, urls } from "../../utils/constants";

const TokenHandler = () => {
  const { token } = useParams();
  const query = useQuery();

  useEffect(() => {
    const client = getInstance({
      Authorization: `Bearer ${token}`
    });
    client.get(checkJwtTokenEndpoint)
      .then(res => {
        window.localStorage.setItem(TOKEN_NAME, token);
        window.location.replace(query.get("u"));
      })
      .catch(err => {
        window.location.replace(urls.users.login);
      })
  }, [token, query]);

  return (
    <div className="loadingContainer uk-flex uk-flex-column uk-flex-middle uk-flex-center">
      <div>
        <img src={logo} alt="F2A" />
      </div>
      <div className="uk-margin-top">
        <Circle />
      </div>
    </div>
  );
};

export default TokenHandler;
