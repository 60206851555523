import Axios from "axios";
import { apiPlatformApiEndpoint, TOKEN_NAME } from "./constants";

const getLoggedInstance = () => {
  const instance = getInstance({
    Authorization: `Bearer ${window.localStorage.getItem(TOKEN_NAME)}`
  });

  return instance;
};

export const getInstance = (headers = null) => {
  let config = {
    baseURL: apiPlatformApiEndpoint
  };

  if (headers !== null) {
    config.headers = headers;
  }

  const instance = Axios.create(config);

  return instance;
};

export default getLoggedInstance;
