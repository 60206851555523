const UserSearch = ({ searchTerm, handleChange, handleReset }) => (
  <form className="uk-margin">
    <legend className="uk-legend">
      Recherche (3 caractères minimum){" "}
      <button
        className="uk-button uk-button-primary uk-button-small"
        title="Réinitialiser"
        onClick={handleReset}
      >
        <span uk-icon="icon: refresh"></span>
      </button>
    </legend>

    <div className="uk-margin-top">
      <input
        className="uk-input uk-form-width-large"
        type="text"
        placeholder="Login, nom..."
        value={searchTerm}
        onChange={handleChange}
      />
    </div>
  </form>
);

export default UserSearch;
