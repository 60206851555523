import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { editCommercial, getCommercial } from "../../services/CommercialService";
import notify from "../../utils/notification";
import { urls } from "../../utils/constants";
import CommercialForm from "./CommercialForm";
import DoubleBounce from "better-react-spinkit/dist/DoubleBounce";

const EditCommercial = ({ match, history }) => {
  const [commercial, setCommercial] = useState(null);

  const { id } = match.params;

  const onSubmit = data => {
    editCommercial(id, data)
      .then(res => {
        notify("Enregistrement mis à jour");
        history.push(urls.users.commercial.list);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    getCommercial(id).then(res => setCommercial(res.data));
  }, [id]);

  return (
    <>
      <h1>Editer commercial</h1>
      {!commercial ? (
        <DoubleBounce />
      ) : (
        <CommercialForm onSubmit={onSubmit} values={commercial} />
      )}
    </>
  );
};

export default withRouter(EditCommercial);
