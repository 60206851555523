import { userApiEndpoint } from "../utils/constants";
import getLoggedInstance, { getInstance } from "../utils/request-utils";
import User from "../models/User";

export const getUsername = () => {
  const client = getLoggedInstance();
  return client.get(`${userApiEndpoint}/username`);
};

export const getUsers = (page = 1, search = null, order = "") => {
  const client = getLoggedInstance();
  let searchQuery = "";
  if (search && search.length >= 3) {
    searchQuery = `&lastName=${search}&login=${search}`;
  }
  if (order !== "") {
    order = "&" + order;
  }
  return client.get(`${userApiEndpoint}?page=${page}${searchQuery}${order}`);
};

export const getUser = (id) => {
  const client = getLoggedInstance();
  return client.get(`${userApiEndpoint}/${id}`);
};

export const createUser = (data, phoneNumbers, apps) => {
  const formattedData = User.getPreparedData(data, phoneNumbers, apps);
  const client = getLoggedInstance();
  return client.post(userApiEndpoint, formattedData);
};

export const updateUser = (id, data, phoneNumbers, apps) => {
  const formattedData = User.getPreparedData(data, phoneNumbers, apps);
  const client = getLoggedInstance();
  return client.put(`${userApiEndpoint}/${id}`, formattedData);
};

export const registerUser = (data) => {
  const formattedData = User.getPreparedData(data, [], data.apps.split(","));
  console.log(formattedData);
  const client = getInstance();
  return client.post(userApiEndpoint, {
    ...formattedData,
    register: true,
  });
};

export const checkUserToken = (token) => {
  const client = getInstance();
  return client.get(`${userApiEndpoint}/token/check/${token}`);
};

export const resetUserPass = (token, password) => {
  const client = getInstance();
  return client.post(`${userApiEndpoint}/reset/${token}`, {
    password: password,
  });
};

export const resetFailedPassword = (userId) => {
  const client = getLoggedInstance();
  return client.get(
    `${userApiEndpoint}/${userId}/reset-password-failed-number`
  );
};

export const grantAdminRole = (id) => {
  const client = getLoggedInstance();
  return client.get(`${userApiEndpoint}/${id}/grant-admin-role`);
};
