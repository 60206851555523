import { useTranslation } from "react-i18next";

import logo from "../../images/logo.png";
import "./Reset.scss";

const ResetSuccess = () => {
  const { t } = useTranslation();

  return (
    <div className="uk-container uk-container-expand uk-margin-top uk-flex uk-flex-center uk-flex-middle uk-flex-column">
      <p className="uk-text-center">
        <img src={logo} alt="F2A Logo" />
      </p>
      <div className="uk-alert-success reset" uk-alert="true">
        {t("reset.success-msg")}
      </div>
      <div className="uk-alert-primary" uk-alert="true">
        {`${t("reset.esonie-connect-info")} `}
        <a
          href={process.env.REACT_APP_ESONIE_URL}
          className="uk-text-bold uk-text-uppercase"
        >
          {t("reset.esonie-link-label")}
        </a>
      </div>
    </div>
  );
};

export default ResetSuccess;
