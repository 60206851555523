import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { editApplication, getApplication } from "../../services/ApplicationService";
import notify from "../../utils/notification";
import { urls } from "../../utils/constants";
import ApplicationForm from "./ApplicationForm";
import DoubleBounce from "better-react-spinkit/dist/DoubleBounce";

const ApplicationEdit = ({ match, history }) => {
  const [application, setApplication] = useState(null);

  const { id } = match.params;

  const onSubmit = data => {
    editApplication(id, data)
      .then(res => {
        notify("Enregistrement mis à jour");
        history.push(urls.applications.list);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    getApplication(id).then(res => setApplication(res.data));
  }, [id]);

  return (
    <>
      <h1>Editer application</h1>
      {!application ? (
        <DoubleBounce />
      ) : (
        <ApplicationForm onSubmit={onSubmit} values={application} />
      )}
    </>
  );
};

export default withRouter(ApplicationEdit);
