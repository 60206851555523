import getLoggedInstance from "../utils/request-utils";
import { groupApiEndpoint } from "../utils/constants";

export const getGroups = (page = 1, name = null) => {
  const client = getLoggedInstance();
  return client.get(`${groupApiEndpoint}?page=${page}`);
};

export const getGroup = (id) => {
  const client = getLoggedInstance();
  return client.get(`${groupApiEndpoint}/${id}`);
};

export const newGroup = (data) => {
  const client = getLoggedInstance();
  return client.post(`${groupApiEndpoint}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const editGroup = (id, data) => {
  const client = getLoggedInstance();
  return client.post(`${groupApiEndpoint}/${id}/edit`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
