import "./NotFound.scss";

const NotFound = () => (
  <div className="uk-container">
    <h1>404 - Page non trouvée</h1>
    <p>
      <button className="uk-button uk-button-default"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Retour à l'accueil
      </button>
    </p>
  </div>
);

export default NotFound;
