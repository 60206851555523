import { prompt } from "../../utils/modal";

const PhoneNumbers = ({ phoneNumbers, onPhoneCreated, removePhone }) => {
  const addNewPhone = e => {
    e.preventDefault();

    prompt("Numéro de téléphone", "", num =>
      onPhoneCreated(num)
    );
  };

  const onClickRemove = e => {
    e.preventDefault();

    const phoneId = e.target.dataset.id;
    removePhone(phoneId);
  }

  return (
    <div className="uk-width-1-3">
      <h3>Numéros de téléphone</h3>
      <ul className="uk-list uk-list-striped">
        {phoneNumbers.map(phoneNumber => (
          <li
            key={phoneNumber.listKey ? phoneNumber.listKey : phoneNumber["@id"]}
          >
            {phoneNumber.phone}
            <button
              className="uk-button uk-button-danger uk-button-small uk-margin-left"
              data-id={
                phoneNumber.listKey ? phoneNumber.listKey : phoneNumber["@id"]
              }
              onClick={onClickRemove}
            >
              <span data-uk-icon="trash" style={{pointerEvents: 'none'}}></span>
            </button>
          </li>
        ))}
      </ul>
      <button
        className="uk-button uk-button-secondary uk-button-small"
        onClick={addNewPhone}
      >
        <span uk-icon="plus"></span>
      </button>
    </div>
  );
};

export default PhoneNumbers;
