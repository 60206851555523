import { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import useIsMounted from "../hooks/useIsMounted";
import Circle from "better-react-spinkit/dist/Circle";

const RemoteDataSelectList = ({
  selectedItem,
  changeItemFn,
  loadingText,
  getItems,
  getListItems,
  anonymous = false
}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMounted = useIsMounted();

  const updateSelect = useCallback(res => {
    const options = getListItems(res);
    if (isMounted) {
      setItems(options);
    }
  }, [getListItems, isMounted]);

  useEffect(() => {
    getItems(anonymous)
      .then(updateSelect)
      .then(() => {
        setLoading(false);
      });
  }, [getItems, updateSelect, anonymous]);

  return loading ? (
    <div>
      <span>{loadingText}</span>
      <Circle />
    </div>
  ) : (
    <Select
      options={items}
      value={selectedItem}
      className="uk-width-large@s"
      onChange={changeItemFn}
    />
  );
};

export default RemoteDataSelectList;
