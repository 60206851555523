export const apiPlatformApiEndpoint = `${process.env.REACT_APP_API_ENTRYPOINT}/api`;

export const userApiEndpoint = `/users`;
export const originApiEndpoint = `/origins`;
export const commercialApiEndpoint = `/commercials`;
export const groupApiEndpoint = `/company_groups`;
export const countryApiEndpoint = `/countries`;
export const applicationApiEndpoint = `/applications`;
export const checkJwtTokenEndpoint = `${userApiEndpoint}/jwt/check`;

export const PAGING_THRESHOLD = 30;
export const TOKEN_NAME = "front-user";

const usersRootUrl = '/users';

export const urls = {
  users: {
    login: "/login",
    logout: "/logout",
    list: usersRootUrl,
    new: `${usersRootUrl}/new`,
    edit: `${usersRootUrl}/edit`,
    origin: {
      list: `${usersRootUrl}/origins`,
      new: `${usersRootUrl}/origins/new`,
      edit: `${usersRootUrl}/origins/edit`,
    },
    commercial: {
      list: `${usersRootUrl}/commercials`,
      new: `${usersRootUrl}/commercials/new`,
      edit: `${usersRootUrl}/commercials/edit`,
    },
    group: {
      list: `${usersRootUrl}/groups`,
      new: `${usersRootUrl}/groups/new`,
      edit: `${usersRootUrl}/groups/edit`,
    }
  },
  applications: {
    list: "/applications",
    new: "/applications/new",
    edit: "/applications/edit"
  },
  register: '/register',
  reset: '/reset',
  resetSuccess: '/success/reset'
};