const RegisterFormErrors = ({ errors }) => {
  const errorKeys = Object.keys(errors);

  return (
    errors &&
    errorKeys.length > 0 && (
      <div className="uk-alert-danger" uk-alert="true">
        {errorKeys.map(errName => (
          <div>{errors[errName].message}</div>
        ))}
      </div>
    )
  );
};

export default RegisterFormErrors;
