import getLoggedInstance, { getInstance } from "../utils/request-utils";
import { applicationApiEndpoint } from "../utils/constants";

export const getApplications = (page = 1, name = null) => {
  const client = getLoggedInstance();
  return client.get(`${applicationApiEndpoint}?page=${page}`);
};

export const getApplicationsAnonymous = () => {
  const client = getInstance();
  return client.get(`${applicationApiEndpoint}?pagination=false`);
}

export const getApplication = id => {
  const client = getLoggedInstance();
  return client.get(`${applicationApiEndpoint}/${id}`);
}

export const newApplication = data => {
  const client = getLoggedInstance();
  return client.post(`${applicationApiEndpoint}`, data);
}

export const editApplication = (id, data) => {
  const client = getLoggedInstance();
  return client.put(`${applicationApiEndpoint}/${id}`, data);
}

export const getListItems = res => mapApiResults(res);

const getItemFromApiResult = e => ({
  value: e["@id"],
  label: e["name"]
});

const mapApiResults = res =>
  res.data["hydra:member"].map(getItemFromApiResult);