import { useState, useEffect, useReducer, useCallback } from "react";
import {
  Column,
  Table,
  AutoSizer,
  InfiniteLoader
} from "react-virtualized";
import { Link } from "react-router-dom";
import { PAGING_THRESHOLD, TOKEN_NAME } from "../../utils/constants";
import { ChasingDots, FadingCircle } from "better-react-spinkit";
import notify from "../../utils/notification";
import { confirm } from "../../utils/modal";
import Axios from "axios";
import useIsMounted from "../../hooks/useIsMounted";
import { tableSortReducer } from "../../reducers/tableSort";

/**
 *
 * @param {getItems} getItems signature must be (page, search)
 */
const InfiniteTable = ({
  children,
  getItems,
  editItemUrl,
  search,
  options = {
    hideRemoveButton: false,
    hideEditButton: false
  },
  initialSort = null
}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [remove, setRemove] = useState(false);
  const [hasRemoved, setHasRemoved] = useState(false);
  const [total, setTotal] = useState(10000);
  const [sortState, dispatchSortAction] = useReducer(tableSortReducer, initialSort);

  const isMounted = useIsMounted();

  const { hideRemoveButton, hideEditButton } = options;

  const isRowLoaded = ({ index }) => {
    return !!items[index];
  };

  const loadMoreRows = ({ startIndex }) => {
    const page = startIndex / PAGING_THRESHOLD + 1;
    if (tableLoading) return;
    if (hasRemoved) {
      setHasRemoved(false);
      return;
    }
    setTableLoading(true);
    getItems(page, search, getOrderQueryParameter())
      .then(res => {
        if (!isMounted.current) {
          return;
        }
        const results = res.data["hydra:member"];
        const newItems = [...items, ...results];
        setItems(newItems);
        setTableLoading(false);
      })
      .catch(err => {
        if (!isMounted.current) {
          return;
        }
        notify(
          "Une erreur est survenue lors de la requête. Essayez de vous déconnecter puis de vous reconnecter",
          "danger"
        );
      });
  };

  const getOrderQueryParameter = useCallback((col = "", direction = "") => {
    if (initialSort === null) {
      return "";
    }

    if (col === "" && direction === "") {
      col = sortState.column;
      direction = sortState.direction;
    }

    return `order[${col}]=${direction}`;
  }, [initialSort, sortState]);

  useEffect(() => {
    setLoading(true);
    getItems(1, search, getOrderQueryParameter())
      .then(res => {
        if (!isMounted.current) {
          return;
        }
        setTotal(res.data["hydra:totalItems"]);
        setItems(res.data["hydra:member"]);
        setLoading(false);
      })
      .catch(err => {
        if (!isMounted.current) {
          return;
        }
        notify(
          "Une erreur est survenue lors de la requête. Essayez de vous déconnecter puis de vous reconnecter",
          "danger"
        );
        setLoading(false);
      });
  }, [getItems, search, isMounted, getOrderQueryParameter]);

  useEffect(() => {
    function confirmRemoval() {
      // TODO: use getLoggedInstance, but adapt the entrypoint
      // because here, currentId is like "/api/xxxxx/uuid"
      // and we already use "/api..." in getLoggedInstance entrypoint
      Axios.delete(`${process.env.REACT_APP_API_ENTRYPOINT}${currentId}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(TOKEN_NAME)}`
        }
      }).then(res => {
        notify("Enregistrement supprimé");
        setRemove(false);
        setHasRemoved(true);
        setItems(items.filter(item => item["@id"] !== currentId));
      });
    }

    if (currentId && remove) {
      confirm(
        "Voulez-vous vraiment supprimer cet enregistrement ?",
        confirmRemoval,
        cancelRemoval
      );
    }
  }, [currentId, remove, items]);

  function cancelRemoval() {
    setRemove(false);
  }

  const sortItems = ({ sortBy, sortDirection }) => {
    if (initialSort === null) {
      return;
    }

    if (sortBy === sortState.column) {
      dispatchSortAction({type: "changeDirection", payload: sortDirection});
    } else {
      dispatchSortAction({type:"changeColumn", payload: sortBy});
    }

    setLoading(true);
    getItems(1, search, getOrderQueryParameter(sortBy, sortDirection))
      .then(res => {
        if (!isMounted.current) {
          return;
        }
        setTotal(res.data["hydra:totalItems"]);
        setItems(res.data["hydra:member"]);
        setLoading(false);
      })
      .catch(err => {
        if (!isMounted.current) {
          return;
        }
        notify(
          "Une erreur est survenue lors de la requête. Essayez de vous déconnecter puis de vous reconnecter",
          "danger"
        );
        setLoading(false);
      });
  }

  return loading ? (
    <ChasingDots />
  ) : (
    <>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={total}
        threshold={4}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <Table
                onRowsRendered={onRowsRendered}
                ref={registerChild}
                width={width}
                height={550}
                headerHeight={30}
                rowHeight={45}
                rowCount={items.length}
                rowStyle={{ borderBottom: "1px solid #888" }}
                rowGetter={({ index }) => items[index]}
                sort={sortItems}
                sortBy={sortState ? sortState.column : null}
                sortDirection={sortState ? sortState.direction : null}
              >
                {/* EDIT BUTTON */}
                {!hideEditButton && (
                  <Column
                    width={45}
                    disableSort={true}
                    dataKey="@id"
                    cellRenderer={({ rowData }) => (
                      <Link
                        to={editItemUrl(rowData)}
                        className="uk-button uk-button-primary uk-button-small"
                      >
                        <span data-uk-icon="pencil"></span>
                      </Link>
                    )}
                  />
                )}
                {/* END EDIT BUTTON */}

                {children}

                {/* REMOVE BUTTON */}
                {!hideRemoveButton && (
                  <Column
                    style={{ marginLeft: "auto" }}
                    width={45}
                    disableSort={true}
                    dataKey="@id"
                    cellRenderer={({ rowData }) => (
                      <button
                        onClick={() => {
                          setCurrentId(rowData["@id"]);
                          setRemove(true);
                        }}
                        className="uk-button uk-button-danger uk-button-small"
                      >
                        <span data-uk-icon="trash"></span>
                      </button>
                    )}
                  />
                )}
                {/* END REMOVE BUTTON */}
              </Table>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
      <div className="uk-flex uk-flex-center uk-margin">
        {tableLoading && <FadingCircle size={35} />}
      </div>
    </>
  );
};

export default InfiniteTable;
