import { withRouter } from "react-router-dom";
import { newGroup } from "../../services/GroupService";
import notify from "../../utils/notification";
import { urls } from "../../utils/constants";
import GroupForm from "./GroupForm";
import { useState } from "react";

const NewGroup = ({ history }) => {
  const [error, setError] = useState(false);

  const onSubmit = (data) => {
    setError(false);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("esonieCustomPrint", data.esonieCustomPrint);
    if (data.headerLogo.length === 1) {
      formData.append(
        "headerLogo",
        data.headerLogo ? data.headerLogo[0] : null,
        data.headerLogo ? data.headerLogo[0].name : null
      );
    }
    if (data.footerLogo.length === 1) {
      formData.append(
        "footerLogo",
        data.footerLogo ? data.footerLogo[0] : null,
        data.footerLogo ? data.footerLogo[0].name : null
      );
    }
    newGroup(formData)
      .then((res) => {
        notify("Enregistrement créé");
        history.push(urls.users.group.list);
      })
      .catch((err) => setError(true));
  };

  return (
    <>
      <h1>Nouveau groupe</h1>
      {error && (
        <div style={{ color: "#F00" }}>
          Une erreur est survenue. Si vous avez essayé d'enregistrer une image
          pour ce groupe, vérifiez bien qu'elle respecte les propriétés
          suivantes :
          <ul>
            <li>Extension : .png ou .svg</li>
            <li>Taille maximum : 50ko</li>
          </ul>
        </div>
      )}
      <GroupForm onSubmit={onSubmit} />
    </>
  );
};

export default withRouter(NewGroup);
