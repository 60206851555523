import getLoggedInstance, { getInstance } from "../utils/request-utils";
import { originApiEndpoint } from "../utils/constants";

export const getOrigins = (page = 1, name = null) => {
  const client = getLoggedInstance();
  return client.get(`${originApiEndpoint}?page=${page}`);
};

export const getOriginsAnonymous = () => {
  const client = getInstance();
  return client.get(`${originApiEndpoint}?pagination=false`);
}

export const getOrigin = id => {
  const client = getLoggedInstance();
  return client.get(`${originApiEndpoint}/${id}`);
}

export const newOrigin = data => {
  const client = getLoggedInstance();
  return client.post(`${originApiEndpoint}`, data);
}

export const editOrigin = (id, data) => {
  const client = getLoggedInstance();
  return client.put(`${originApiEndpoint}/${id}`, data);
}

export const getListItems = res => mapApiResults(res);

const getItemFromApiResult = e => ({
  value: e["@id"],
  label: e["name"]
});

const mapApiResults = res =>
  res.data["hydra:member"].map(getItemFromApiResult);