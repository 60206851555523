import { useRef, useEffect } from 'react';

/**
 * useIsMounted hook can be used to check if a component is still mounted.
 * Then, one can prevent a state update on an unmounted component, and therefore avoid memory leaks
 */
function useIsMounted() {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}

export default useIsMounted;