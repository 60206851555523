import { Suspense } from "react";
import { Router, Route, Switch } from "react-router-dom";

import { PrivateRoute } from "../utils/PrivateRoute";
import Login from "./Login";
import Logout from "./Logout";
import NotFound from "./NotFound";
import { StateProvider, initialState } from "../state/state";
import { userReducer } from "../reducers/user";
import MainLayoutRoute from "./Routes/MainLayoutRoute";
import history from "../utils/history";
import Users from "./Users/Users";
import DefaultPage from "./DefaultPage/DefaultPage";
import Applications from "./Applications";
import ApplicationNew from "./Applications/New";
import ApplicationEdit from "./Applications/Edit";
import { urls } from "../utils/constants";
import UserEdit from "./Users/Edit";
import Origins from "./Origins/Origins";
import NewOrigin from "./Origins/New";
import EditOrigin from "./Origins/Edit";
import Commercials from "./Commercials";
import NewCommercial from "./Commercials/New";
import EditCommercial from "./Commercials/Edit";
import Groups from "./Groups";
import NewGroup from "./Groups/New";
import EditGroup from "./Groups/Edit";
import NewUser from "./Users/New";
import Register from "./Register";
import Reset from "./Reset";
import ResetSuccess from "./Reset/ResetSuccess";
import TokenHandler from "./TokenHandler/TokenHandler";

const App = () => {
  return (
    <Suspense fallback="loading">
      <StateProvider initialState={initialState} reducer={userReducer}>
        <Router history={history}>
          <div>
            <Switch>
              <Route path="/auth/:token" component={TokenHandler} />
              <MainLayoutRoute exact path="/" component={DefaultPage} />
              
              {/* USERS */}
              <MainLayoutRoute exact path={urls.users.list} component={Users} />
              <MainLayoutRoute exact path={urls.users.new} component={NewUser} />
              <MainLayoutRoute path={`${urls.users.edit}/:id`} component={UserEdit} />

              {/* ORIGINS */}
              <MainLayoutRoute exact path={urls.users.origin.list} component={Origins} />
              <MainLayoutRoute exact path={urls.users.origin.new} component={NewOrigin} />
              <MainLayoutRoute path={`${urls.users.origin.edit}/:id`} component={EditOrigin} />

              {/* COMMERCIALS */}
              <MainLayoutRoute exact path={urls.users.commercial.list} component={Commercials} />
              <MainLayoutRoute exact path={urls.users.commercial.new} component={NewCommercial} />
              <MainLayoutRoute path={`${urls.users.commercial.edit}/:id`} component={EditCommercial} />

              {/* GROUPS */}
              <MainLayoutRoute exact path={urls.users.group.list} component={Groups} />
              <MainLayoutRoute exact path={urls.users.group.new} component={NewGroup} />
              <MainLayoutRoute path={`${urls.users.group.edit}/:id`} component={EditGroup} />

              {/* APPLICATIONS */}
              <MainLayoutRoute exact path={urls.applications.list} component={Applications} />
              <MainLayoutRoute exact path={urls.applications.new} component={ApplicationNew} />
              <MainLayoutRoute path={`${urls.applications.edit}/:id`} component={ApplicationEdit} />

              <PrivateRoute exact path={urls.users.logout} component={Logout} />
              <Route exact path={`${urls.resetSuccess}`} component={ResetSuccess} />
              <Route exact path={`${urls.reset}/:token`} component={Reset} />
              <Route exact path={urls.users.login} component={Login} />
              <Route exact path={urls.register} component={Register} />

              <Route component={NotFound} />
            </Switch>
          </div>
        </Router>
      </StateProvider>
    </Suspense>
  );
};

export default App;
