import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { editOrigin, getOrigin } from "../../services/OriginService";
import notify from "../../utils/notification";
import { urls } from "../../utils/constants";
import OriginForm from "./OriginForm";
import DoubleBounce from "better-react-spinkit/dist/DoubleBounce";

const EditOrigin = ({ match, history }) => {
  const [origin, setOrigin] = useState(null);

  const { id } = match.params;

  const onSubmit = data => {
    editOrigin(id, data)
      .then(res => {
        notify("Enregistrement mis à jour");
        history.push(urls.users.origin.list);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    getOrigin(id).then(res => setOrigin(res.data));
  }, [id]);

  return (
    <>
      <h1>Editer provenance</h1>
      {!origin ? (
        <DoubleBounce />
      ) : (
        <OriginForm onSubmit={onSubmit} values={origin} />
      )}
    </>
  );
};

export default withRouter(EditOrigin);
