import Menu from "./Menu";

const MainLayout = ({ children }) => {
  return (
    <div className="uk-margin-bottom">
      <Menu />
      <div className="uk-container">
        {children}
      </div>
    </div>
  );
};

export default MainLayout;