import getLoggedInstance from "../utils/request-utils";
import { commercialApiEndpoint } from "../utils/constants";

export const getCommercials = (page = 1, name = null) => {
  const client = getLoggedInstance();
  return client.get(`${commercialApiEndpoint}?page=${page}`);
};

export const getCommercial = id => {
  const client = getLoggedInstance();
  return client.get(`${commercialApiEndpoint}/${id}`);
}

export const newCommercial = data => {
  const client = getLoggedInstance();
  return client.post(`${commercialApiEndpoint}`, data);
}

export const editCommercial = (id, data) => {
  const client = getLoggedInstance();
  return client.put(`${commercialApiEndpoint}/${id}`, data);
}