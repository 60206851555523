import { FadingCircle } from "better-react-spinkit";
import { useState } from "react";
import { resetFailedPassword } from "../../services/UserService";
import notify from "../../utils/notification";

const UserResetFailedPassword = ({ user }) => {
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setLoading(true);
    resetFailedPassword(user.id)
      .then(() => {
        user.passwordFailedNumber = 0;
        notify("Nombre de tentatives échouées remis à 0");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="uk-margin-medium-bottom">
      Nombre de tentatives de connexion échouées :{" "}
      <span className="uk-text-bold uk-text-large">
        {user.passwordFailedNumber}
      </span>
      <button
        className="uk-button uk-button-primary uk-margin-left"
        disabled={loading}
        onClick={reset}
      >
        {loading ? <FadingCircle /> : "Réinitialiser"}
      </button>
      <br />
      <span className="uk-badge uk-background-secondary">
        Note : ce nombre est automatiquement remis à 0 toutes les nuits, pour
        tous les utilisateurs en-dessous de la limite
      </span>
    </div>
  );
};

export default UserResetFailedPassword;
