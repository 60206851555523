export const userReducer = (state, action) => {
  switch (action.type) {
    case "changeUsername":
      return {
        ...state,
        user: {
          isLogged: true,
          username: action.username
        }
      };
    case "logout":
      return {
        ...state,
        user: {
          username: "",
          isLogged: false
        }
      };
    default:
      return state;
  }
};
