import { PrivateRoute } from "../../utils/PrivateRoute";
import MainLayout from "../MainLayout";

const MainLayoutRoute = ({ component: Component, ...rest }) => (
  <PrivateRoute
    {...rest}
    render={matchProps => (
      <MainLayout>
        <Component {...matchProps} />
      </MainLayout>
    )}
  />
);

export default MainLayoutRoute;
