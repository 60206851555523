const CommercialFormErrors = ({ errors }) => (
  errors && Object.keys(errors).length > 0 && (
    <div className="uk-alert-danger" uk-alert="true">
      {errors.name && (
        <>
          Le nom est obligatoire
        </>
      )}
    </div>
  )
);

export default CommercialFormErrors;