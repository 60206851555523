import { Link } from 'react-router-dom';
import { extractIdFromJsonLd } from "../../utils/api_platform";
import { Column } from "react-virtualized";
import { urls } from "../../utils/constants";
import InfiniteTable from "../InfiniteTable/InfiniteTable";
import { getApplications } from "../../services/ApplicationService";

const Applications = () => (
  <>
    <h1>Applications</h1>
    <Link
      to={urls.applications.new}
      className="uk-button uk-button-secondary uk-button-small"
    >
      <span uk-icon="plus"></span>
    </Link>
    <InfiniteTable
      getItems={getApplications}
      editItemUrl={rowData =>
        `${urls.applications.edit}/${extractIdFromJsonLd(rowData["@id"])}`
      }
    >
      <Column label="Nom" dataKey="name" width={300} disableSort={true} />
      <Column label="URL" dataKey="url" width={600} disableSort={true} />
      <Column label="Code" dataKey="code" width={200} disableSort={true} />
    </InfiniteTable>
  </>
);

export default Applications;
