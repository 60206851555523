import { forwardRef } from "react";

const TextInput = forwardRef(function textInput(
  { name, id = "", placeholder = "", cssClass = "", type="text", ...rest },
  ref
) {
  return (
    <input
      className={`uk-input ${cssClass}`}
      type={type}
      placeholder={placeholder}
      name={name}
      id={id}
      ref={ref}
      {...rest}
    />
  );
});

export default TextInput;
