import { createRoot } from "react-dom/client";
import App from "./components/App";
import "react-virtualized/styles.css";
import "./css/index.scss";
import "./i18n";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

UIkit.use(Icons);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
