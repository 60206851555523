import { useState, useEffect } from "react";
import useForm from "react-hook-form";
import Select from "react-select";
import { Circle } from "better-react-spinkit";

import User from "../../models/User";
import TextInput from "../../utils/TextInput";
import { getGroups } from "../../services/GroupService";
import { getCommercials } from "../../services/CommercialService";
import { getCountries } from "../../services/CountryService";
import { getOrigins } from "../../services/OriginService";
import { getApplications } from "../../services/ApplicationService";
import UserFormErrors from "./UserFormErrors";
import useIsMounted from "../../hooks/useIsMounted";
import PhoneNumbers from "./PhoneNumbers";

const UserForm = ({
  onSubmit,
  loading,
  phoneNumbers,
  onPhoneCreated,
  removePhone,
  apps,
  onAppChange,
  values = new User(),
}) => {
  const { register, formState, errors, setValue, getValues, handleSubmit } =
    useForm();
  const [groups, setGroups] = useState([]);
  const [commercials, setCommercials] = useState([]);
  const [countries, setCountries] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [applications, setApplications] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const isMounted = useIsMounted();

  useEffect(() => {
    if (!formState.dirty) {
      const formValues = getValues();
      Object.keys(formValues).forEach((formKey) => {
        if (formKey in values) {
          setValue(formKey, values[formKey]);
        }
      });
    }
  }, [getValues, setValue, values, formState.dirty]);

  useEffect(() => {
    getGroups().then((res) => {
      if (isMounted.current) {
        setGroups(res.data["hydra:member"]);
      }
    });
    getCommercials().then((res) => {
      if (isMounted.current) {
        setCommercials(res.data["hydra:member"]);
      }
    });
    getCountries().then((res) => {
      if (isMounted.current) {
        const options = res.data["hydra:member"].map((e) => ({
          value: e["@id"],
          label: e["name"],
        }));
        setCountries(options);
      }
    });
    getOrigins().then((res) => {
      if (isMounted.current) {
        setOrigins(res.data["hydra:member"]);
      }
    });
    getApplications().then((res) => {
      if (isMounted.current) {
        setApplications(res.data["hydra:member"]);
      }
    });
  }, [isMounted]);

  useEffect(() => {
    setValue("companyGroup", values.companyGroup);
  }, [setValue, values.companyGroup, groups]);

  useEffect(() => {
    setValue("commercial", values.commercial);
  }, [setValue, values.commercial, commercials]);

  useEffect(() => {
    setValue("origin", values.origin);
  }, [setValue, values.origin, origins]);

  useEffect(() => {
    setValue("country", values.country.value);
    setSelectedCountry(values.country);
  }, [setValue, values.country, countries]);

  const changeCountry = (country) => {
    setValue("country", country.value);
    setSelectedCountry(country);
  };

  const containsApp = (idApp) => {
    for (const app of apps) {
      if (app["@id"] !== undefined && app["@id"] === idApp) {
        return true;
      } else if (app === idApp) {
        return true;
      }
    }
    return false;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UserFormErrors errors={errors} />
      <p>
        <TextInput
          name="login"
          id="login"
          placeholder="Login..."
          ref={register({ required: true })}
          cssClass={`uk-form-width-large uk-margin-right ${
            errors.login && "uk-form-danger"
          }`}
        />
        <TextInput
          type="email"
          name="email"
          id="email"
          placeholder="Email..."
          ref={register({ required: true })}
          cssClass={`uk-form-width-large ${errors.email && "uk-form-danger"}`}
        />
      </p>
      <p>
        <TextInput
          name="firstName"
          id="firstName"
          placeholder="Prénom..."
          ref={register({ required: true })}
          cssClass={`uk-form-width-large uk-margin-right ${
            errors.firstName && "uk-form-danger"
          }`}
        />
        <TextInput
          name="lastName"
          id="lastName"
          placeholder="Nom..."
          ref={register({ required: true })}
          cssClass={`uk-form-width-large ${
            errors.lastName && "uk-form-danger"
          }`}
        />
      </p>
      <p>
        <TextInput
          name="gender"
          id="gender"
          placeholder="Genre..."
          ref={register}
          cssClass="uk-form-width-large"
        />
      </p>
      <p>
        <TextInput
          name="company"
          id="company"
          placeholder="Société..."
          ref={register}
          cssClass="uk-form-width-large"
        />
      </p>
      <div className="uk-flex uk-flex-left uk-flex-bottom">
        <div className="uk-margin-right">
          <TextInput
            name="city"
            id="city"
            placeholder="Ville..."
            ref={register}
            cssClass="uk-form-width-large"
          />
        </div>
        <div>
          <label htmlFor="country">Pays :</label>
          <br />
          <input type="hidden" name="country" ref={register} />
          <Select
            options={countries}
            value={selectedCountry}
            className="uk-form-width-large"
            onChange={changeCountry}
          />
        </div>
      </div>
      <p>
        <TextInput
          name="postalCode"
          id="postalCode"
          placeholder="Code postal..."
          ref={register}
          cssClass="uk-form-width-large"
        />
      </p>
      <p>
        <TextInput
          name="codeErp"
          id="codeErp"
          placeholder="Code ERP..."
          ref={register}
          cssClass="uk-form-width-large"
        />
      </p>
      <p>
        <input
          type="checkbox"
          name="locked"
          id="locked"
          ref={register}
          className="uk-checkbox uk-margin-small-right"
        />
        <label htmlFor="locked">Compte bloqué</label>
      </p>
      <p>
        <input
          type="checkbox"
          name="hasValidatedCgu"
          id="hasValidatedCgu"
          ref={register}
          className="uk-checkbox uk-margin-small-right"
        />
        <label htmlFor="hasValidatedCgu">CGU validées</label>
      </p>
      <p>
        <input
          type="checkbox"
          name="f2AirClient"
          id="f2AirClient"
          ref={register}
          className="uk-checkbox uk-margin-small-right"
        />
        <label htmlFor="f2AirClient">Client F2Air</label>
      </p>
      <div className="uk-flex uk-flex-left">
        <div className="uk-margin-right">
          <label htmlFor="companyGroup">Groupe :</label>
          <br />
          <select
            name="companyGroup"
            id="companyGroup"
            className="uk-select uk-form-width-large"
            defaultValue={values.companyGroup}
            ref={register}
          >
            <option value="">-- Choisissez un groupe --</option>
            {groups.map((g) => (
              <option value={g["@id"]} key={g["@id"]}>
                {g.name}
              </option>
            ))}
          </select>
        </div>
        <div className="uk-margin-right">
          <label htmlFor="commercial">Commercial :</label>
          <br />
          <select
            name="commercial"
            id="commercial"
            className="uk-select uk-form-width-large"
            defaultValue={values.commercial}
            ref={register}
          >
            <option value="">-- Choisissez un commercial --</option>
            {commercials.map((c) => (
              <option value={c["@id"]} key={c["@id"]}>
                {c.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="origin">Provenance :</label>
          <br />
          <select
            name="origin"
            id="origin"
            className="uk-select uk-form-width-large"
            defaultValue={values.origin}
            ref={register}
          >
            <option value="">-- Choisissez une provenance --</option>
            {origins.map((o) => (
              <option value={o["@id"]} key={o["@id"]}>
                {o.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="uk-margin-top" uk-grid="true">
        <PhoneNumbers
          phoneNumbers={phoneNumbers}
          onPhoneCreated={onPhoneCreated}
          removePhone={removePhone}
        />
        <div className="uk-width-1-3">
          <h3>Accès aux applications</h3>
          <table className="uk-table uk-table-striped">
            <tbody>
              {applications.map((app) => (
                <tr key={app["@id"]}>
                  <td>{app.name}</td>
                  <td>
                    <input
                      type="checkbox"
                      className="uk-checkbox"
                      checked={containsApp(app["@id"])}
                      data-idapp={app["@id"]}
                      onChange={onAppChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="uk-margin-top">
        <button className="uk-button uk-button-secondary" disabled={loading}>
          {loading ? (
            <Circle />
          ) : (
            <>
              <span uk-icon="check"></span> ENREGISTRER
            </>
          )}
        </button>
      </div>
    </form>
  );
};

export default UserForm;
