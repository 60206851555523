import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import UserForm from "./UserForm";
import { getUser, updateUser } from "../../services/UserService";
import { DoubleBounce } from "better-react-spinkit";
import User from "../../models/User";
import { urls } from "../../utils/constants";
import notify from "../../utils/notification";
import { randomString } from "../../utils/utils";
import UserResetFailedPassword from "./UserResetFailedPassword";
import GrantAdminRole from "./GrantAdminRole";
import ReinitLink from "./ReinitLink";

const EditUser = ({ match, history }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const { id } = match.params;

  const onPhoneCreated = (num) => {
    if (num === null) {
      return;
    }

    const newPhoneNumber = {
      listKey: randomString(),
      phone: num,
    };
    const phoneNumbers = user.phoneNumbers;
    const modifiedPhoneNumbers = [...phoneNumbers, newPhoneNumber];
    setUser({ ...user, phoneNumbers: modifiedPhoneNumbers });
  };

  const removePhone = (phoneId) => {
    const phoneNumbers = user.phoneNumbers;

    const filteredPhones = phoneNumbers.filter((phoneNumber) => {
      if ("@id" in phoneNumber) {
        return phoneNumber["@id"] !== phoneId;
      }

      return phoneNumber.listKey !== phoneId;
    });

    setUser({ ...user, phoneNumbers: filteredPhones });
  };

  const onAppChange = (e) => {
    const checkbox = e.target;
    const apps = user.applications;

    if (checkbox.checked) {
      // adding app
      const newApp = checkbox.dataset.idapp;
      const modifiedApps = [...apps, newApp];
      setUser({ ...user, applications: modifiedApps });
    } else {
      const removeApp = checkbox.dataset.idapp;
      const modifiedApps = apps.filter((app) => {
        if (app["@id"] !== undefined) {
          return app["@id"] !== removeApp;
        }
        return app !== removeApp;
      });
      setUser({ ...user, applications: modifiedApps });
    }
  };

  const onSubmit = (data) => {
    setLoading(true);

    updateUser(id, data, user.phoneNumbers, user.applications).then(
      (res) => {
        notify("Informations mises à jour");
        setLoading(false);
        history.push(urls.users.list);
      },
      (err) => {
        notify(
          "Une erreur est survenue. Essayez de vous déconnecter puis de vous reconnecter",
          "danger"
        );
        setLoading(false);
        history.push(urls.users.list);
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getUser(id).then((res) => {
      const formUser = User.fromUserData(res.data);
      setUser(formUser);
    });
  }, [id]);

  return (
    <>
      <h1>Edition utilisateur</h1>
      {!user ? (
        <DoubleBounce />
      ) : (
        <>
          {user.registrationDate ? (
            <div className="uk-alert-primary" uk-alert="true">
              <p>Date d'inscription : {user.registrationDate}</p>
            </div>
          ) : (
            ""
          )}
          <UserResetFailedPassword user={user} />
          <GrantAdminRole id={user.id} />
          <ReinitLink user={user} />
          <UserForm
            onSubmit={onSubmit}
            loading={loading}
            values={user}
            phoneNumbers={user.phoneNumbers}
            onPhoneCreated={onPhoneCreated}
            removePhone={removePhone}
            apps={user.applications}
            onAppChange={onAppChange}
          />
        </>
      )}
    </>
  );
};

export default withRouter(EditUser);
