import { withRouter } from 'react-router-dom';
import { newCommercial } from "../../services/CommercialService";
import notify from "../../utils/notification";
import { urls } from "../../utils/constants";
import CommercialForm from "./CommercialForm";

const NewCommercial = ({ history }) => {
  const onSubmit = data => {
    newCommercial(data)
      .then(res => {
        notify('Enregistrement créé');
        history.push(urls.users.commercial.list);
      })
      .catch(err => console.error(err));
  }

  return (
    <>
      <h1>Nouveau commercial</h1>
      <CommercialForm onSubmit={onSubmit} />
    </>
  );
};

export default withRouter(NewCommercial);
