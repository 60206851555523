import getLoggedInstance, { getInstance } from "../utils/request-utils";
import { countryApiEndpoint } from "../utils/constants";

export const getCountries = (anonymous = false) => {
  const client = anonymous ? getInstance() : getLoggedInstance();
  return client.get(`${countryApiEndpoint}?pagination=false`);
};

export const getListItems = res => mapApiResults(res);

const getItemFromApiResult = e => ({
  value: e["@id"],
  label: e["name"]
});

const mapApiResults = res =>
  res.data["hydra:member"].map(getItemFromApiResult);