import InfiniteTable from "../InfiniteTable/InfiniteTable";
import { getCommercials } from "../../services/CommercialService";
import { urls } from "../../utils/constants";
import { extractIdFromJsonLd } from "../../utils/api_platform";
import { Column } from "react-virtualized";
import { Link } from "react-router-dom";

const Commercials = () => (
  <>
    <h1>COMMERCIAUX</h1>
    <Link
      to={urls.users.commercial.new}
      className="uk-button uk-button-secondary uk-button-small"
    >
      <span uk-icon="plus"></span>
    </Link>
    <InfiniteTable
      getItems={getCommercials}
      editItemUrl={rowData =>
        `${urls.users.commercial.edit}/${extractIdFromJsonLd(rowData["@id"])}`
      }
    >
      <Column label="Libellé" dataKey="name" width={400} disableSort={true} />
    </InfiniteTable>
  </>
);

export default Commercials;
