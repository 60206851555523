import Circle from "better-react-spinkit/dist/Circle";

import logo from "../../images/logo.png";

const ResetLoading = () => (
  <div className="uk-container uk-container-expand uk-margin-top uk-flex uk-flex-center uk-flex-column">
    <p className="uk-text-center">
      <img src={logo} alt="F2A Logo" />
    </p>
    <div className="uk-flex uk-flex-center">
      <Circle size={35} />
    </div>
  </div>
);

export default ResetLoading;
