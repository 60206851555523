import { useState, useEffect } from "react";
import useForm from "react-hook-form";
import { useTranslation } from "react-i18next";

import logo from "../../images/logo.png";
import "./Reset.scss";
import DoubleBounce from "better-react-spinkit/dist/DoubleBounce";
import ResetErrors from "./ResetErrors";
import { checkUserToken, resetUserPass } from "../../services/UserService";
import notify from "../../utils/notification";
import history from "../../utils/history";
import { urls } from "../../utils/constants";
import ResetLoading from "./ResetLoading";
import LanguageSwitcher from "../Language/LanguageSwitcher";

const Reset = ({ match }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [validToken, setValidToken] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const { register, handleSubmit, setError, clearError, errors } = useForm();

  const { token } = match.params;

  useEffect(() => {
    checkUserToken(token)
      .then(res => {
        setValidToken(true);
      })
      .catch(err => {
        setValidToken(false);
        setErrorMsg(
          t('reset.token-error')
        );
      })
      .finally(() => setLoading(false));
  }, [token, t]);

  const onSubmit = data => {
    if (data["password"] !== data["passwordConfirm"]) {
      setError(
        "password",
        "notMatch",
        t('reset.password-error')
      );
    } else {
      setSubmitting(true);
      clearError("password");
      resetUserPass(token, data["password"])
        .then(res => history.push(urls.resetSuccess))
        .catch(err => {
          setSubmitting(false);
          notify(t('global.error_occured'), "danger");
        });
    }
  };

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  if (loading) {
    return <ResetLoading />;
  }

  if (!validToken && !loading) {
    return (
      <div className="uk-container uk-margin-top uk-flex uk-flex-middle uk-flex-column reset">
        <div className="uk-text-center">
          <img src={logo} alt="F2A Logo" />
        </div>
        <p className="uk-text-center">
          <LanguageSwitcher changeLanguageFn={changeLanguage} />
        </p>
        <div className="uk-alert-danger reset" uk-alert="true">
          {errorMsg}
        </div>
      </div>
    );
  }

  return (
    <div className="uk-container uk-container-expand uk-margin-top uk-flex uk-flex-center">
      <form onSubmit={handleSubmit(onSubmit)} className="reset">
        <p className="uk-text-center">
          <img src={logo} alt="F2A Logo" />
        </p>
        <p className="uk-text-center">
          <LanguageSwitcher changeLanguageFn={changeLanguage} />
        </p>
        <h2>{t('reset.title')}</h2>
        <ResetErrors errors={errors} />
        <p>
          <label htmlFor="password">{t('register.password')}</label>
          <input
            type="password"
            name="password"
            className="uk-input"
            id="password"
            ref={register({ required: true })}
          />
        </p>
        <p>
          <label htmlFor="passwordConfirm">{t('register.confirmPassword')}</label>
          <input
            type="password"
            name="passwordConfirm"
            className="uk-input"
            id="passwordConfirm"
            ref={register({ required: true })}
          />
        </p>
        <div className="uk-text-center uk-margin-top">
          {submitting ? (
            <div className="uk-flex uk-flex-center">
              <DoubleBounce />
            </div>
          ) : (
            <button
              className="uk-button uk-button-primary"
              disabled={submitting}
            >
              {t('reset.submit')}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Reset;
