import { useEffect } from "react";
import { useStateValue } from "../../state/state";
import logo from "../../images/logo.png";
import { DoubleBounce } from "better-react-spinkit";
import { getUsername } from "../../services/UserService";
import { NavLink, Link } from "react-router-dom";

import "./Menu.scss";
import { urls } from "../../utils/constants";

const Menu = () => {
  const [state, dispatch] = useStateValue();

  useEffect(() => {
    if (state.user.isLogged) {
      getUsername()
        .then(res => {
          dispatch({ type: "changeUsername", username: res.data });
        })
        .catch(err => {
          window.location.replace(urls.users.login);
        });
    }
  }, [state.user.isLogged, dispatch]);

  return (
    <nav className="uk-navbar-container uk-margin" uk-navbar="true">
      <div className="uk-navbar-left">
        <NavLink className="uk-navbar-item uk-logo" to="/">
          <img src={logo} alt="F2A" />
        </NavLink>

        <ul className="uk-navbar-nav">
          <li>
            <NavLink to={urls.users.list}>
              <span
                className="uk-icon uk-margin-small-right"
                data-uk-icon="user"
              ></span>
              Utilisateurs
            </NavLink>
            <div className="uk-navbar-dropdown">
              <ul className="uk-nav uk-navbar-dropdown-nav">
                <li>
                  <Link to={urls.users.origin.list}>Provenances</Link>
                </li>
                <li>
                  <Link to={urls.users.commercial.list}>Commerciaux</Link>
                </li>
                <li>
                  <Link to={urls.users.group.list}>Groupes</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <NavLink to={urls.applications.list}>
              <span
                className="uk-icon uk-margin-small-right"
                data-uk-icon="folder"
              ></span>
              Applications
            </NavLink>
          </li>
        </ul>

        <div className="uk-navbar-item">
          <form>
            <input
              className="uk-input uk-form-width-medium"
              type="text"
              placeholder="Nom d'utilisateur..."
            />
            <button className="uk-button uk-button-primary">Rechercher</button>
          </form>
        </div>
      </div>

      <div className="uk-navbar-item uk-navbar-right">
        <div>
          {state.user.username !== "" ? (
            <span>
              {state.user.username}{" "}
              <Link
                to={urls.users.logout}
                alt="Déconnexion"
                className="uk-label uk-label-danger logout-link"
              >
                Déconnexion
              </Link>
            </span>
          ) : (
            <DoubleBounce />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Menu;
